import React, { Component, useState, useCallback, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import LandingHeader from '../components/LandingHeader.js';
import NavbarAI from '../components/NavbarAI.jsx';

import ElectricGridBg from '../components/ElectricGridBg.js';
import Landing_intro from './Landing_intro.jsx';
import Landing_news from './Landing_news.jsx';
import Landing_about from './Landing_about.jsx';
import PageFooter from '../components/PageFooter.jsx';
import Landing_courses from './Landing_courses.jsx';
import { Visibility, Ref, Segment} from 'semantic-ui-react';
import {useWindowSize} from '../hooks/myHooks';
import { useHistory } from "react-router-dom";
import {Helmet} from 'react-helmet';



export default function LandingPage(props){
    const [wwidth, wheight] = useWindowSize();
    const [navOpacity, setNavOpacity] = useState(0);
    const contextRef = useRef();
    const [intoView, setIntoView] = useState({})
    const [passedCrt, setPassedCrt] = useState(false);
    const [visEnable, setVisEnable] = useState(false);
    const [current, setCurrent] = useState(" ");
    const history = useHistory();
    let visibil = {};

    const handleUpdate = useCallback(
        (e, data) => {
            let calc = data.calculations;
            if(calc.offScreen)
                setNavOpacity(1);
            else{
                let op = Math.max(0.0, 1 - (calc.height - calc.pixelsPassed - 72)/72);
                setNavOpacity(op);
            }
        },
        [],
    );

    for(let key of ['intro', 'news', 'about', 'courses', 'contact'])
        visibil[key] = (e, data) => {
                let calc = data.calculations;
                if(visEnable && ( calc.height - calc.pixelsPassed) / wheight < 0.5 )
                    setPassedCrt(true);
                if(calc.topVisible && calc.direction == 'down' && passedCrt)
                    history.push(`/home/${key}`)
                if(calc.bottomVisible && calc.direction == 'up' && passedCrt)
                    history.push(`/home/${key}`)
            }

    useEffect(() => {
        if(!props.match) {
            contextRef.current.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest"});
            return;
        }
        let stm = setTimeout(()=>{
            const navto = props.match.params.segment;
            setVisEnable(false);
            setPassedCrt(false);
            if(intoView[navto]){
                intoView[navto]();
                setTimeout(()=>setVisEnable(true), 50);
            }
        }, 1);
        return ()=>{
            clearTimeout(stm);
        }
    }, [props]);

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);
    
    return(
    <Ref innerRef={contextRef}>
        <div className = "page" >
            <Helmet>
                <title>Academia de Informatică</title>
            </Helmet>
            <ElectricGridBg
                width="100wh"   
                height="100vh"
                style = {{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: -5
                }}
                />
                {wwidth > 700 ?
                <>
                    <Visibility onUpdate={handleUpdate}>
                        <LandingHeader />
                    </Visibility>
                    <NavbarAI opacity = {navOpacity} />
                </>
                :
                <NavbarAI />
                }
                <Visibility onUpdate={visibil['intro']}>
                    <Landing_intro id="intro_id" intoViewHandler={(iv)=>intoView['intro'] = iv} />
                </Visibility>
                <Visibility onUpdate={visibil['news']}>
                    <Landing_news intoViewHandler={(iv)=>intoView['news'] = iv} />
                </Visibility>
                <Visibility onUpdate={visibil['about']}>
                    <Landing_about intoViewHandler={(iv)=>intoView['about'] = iv} />
                </Visibility>
                <Visibility onUpdate={visibil['courses']}>
                    <Landing_courses intoViewHandler={(iv)=>intoView['courses'] = iv} />
                </Visibility>
                <Visibility onUpdate={visibil['contact']}>
                    <PageFooter intoViewHandler={(iv)=>intoView['contact'] = iv} />
                </Visibility>
        </div>
        
    </Ref>
    )
}