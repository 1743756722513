import React, { Component } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import style from '../styles/navbar.module.scss'
import logo from '../assets/sigla1024.png'
import banner from '../assets/banner1.svg'
import { Link } from 'react-router-dom'

let navItems = [
    { text: 'Despre', path: '/home/about' },
    { text: 'Cursuri', path: '/courses' },
    { text: 'Noutăți', path: '/home/news' },
    { text: 'Realizări', path: '/achievements' },
    { text: 'Înscriere', path: '/signup' },
    // { text: 'Susțineți-ne!', path: '/sponsor' },
    { text: 'Contact', path: '/contact' },
]

export default function NavbarAI(props) {
    const { opacity = 1 } = props;
    return (
        <Navbar style={{ display: opacity > 0 ? 'flex' : 'none', opacity }} className={style.navbar} variant="dark" collapseOnSelect expand='md' fixed="top">
            <Navbar.Brand as={Link} to={'/'} className={style.navBrand}>
                <img
                    src={banner}
                />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <Nav className={`ml-auto ${style.navContainer}`}>
                    {
                        navItems.map(link => (
                            <Nav.Link as={Link} key={link.text} to={link.path} href="#" className={style.navItem}>
                                <span>{link.text}</span>
                            </Nav.Link>
                        ))
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
