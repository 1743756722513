import React from 'react'
import ElectricGridBg from '../components/ElectricGridBg.js'
import NavbarAI from '../components/NavbarAI'
import DefaultPage, { PageSegment } from '../components/DefaultPage.jsx'
import { Helmet } from 'react-helmet'

export default function Signup() {
    return (
        <DefaultPage style={{height:'100vh', overflow:'hidden'}}>
            <Helmet>
                <title>A[i] - Preînscriere</title>
            </Helmet>
            <NavbarAI />
            <div style={{marginTop: 80}}></div>
            <PageSegment 
                bot 
                style={{
                    paddingBottom: '4px', 
                    marginBottom: '4px', 
                    height: '100%'
                }}
                ///TODO: fix this to make more usable space on mobile.
                innerStyle={{
                    paddingTop: 8
                }}
                >
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScd0R8ngZWh3GKSTgYpQOITqNYi0SAWFjITGM1M3PouIAVjlQ/viewform?embedded=true" 
                    width="100%"
                    height={"90%"}
                    frameborder="0" 
                    marginheight="0" 
                    marginwidth="0"
                    style={{
                        borderRadius: "10px",
                        // height:'100%'
                    }}
                >
                    Loading…
                </iframe>
            </PageSegment>

        </DefaultPage>
    )
}
