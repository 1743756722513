import React from 'react'
import DefaultPage, { PageSegment } from '../components/DefaultPage'
import NavbarAI from '../components/NavbarAI'
import PageFooter from '../components/PageFooter'
import axios from 'axios'
import { useState, useEffect, useRef, useCallback} from 'react'
import { Placeholder, Loader, Dimmer, Segment, Image, Rail, Item} from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import htmlParse from 'html-react-parser';
import Slider from 'react-slick'
import style from '../styles/article.module.scss';
import ImgsViewer from 'react-images-viewer';
import { Helmet } from 'react-helmet'
import {ShareButton, Like} from 'react-facebook/dist/ShareButton'
import HelpIcon from '../assets/Icons/help_color.svg'
import { Link } from 'react-router-dom'

// --- PHOTOS ---
import photo_banner from '../assets/staticTemp/inventronica/Inventronica_banner.png'
import photo_inventronica from '../assets/staticTemp/inventronica/Inventronica_CDI.png'
import photo_hometronica from '../assets/staticTemp/inventronica/hometronica_new.png'
import photo_microbit from '../assets/staticTemp/inventronica/microbit_post.png'
import photo_tehnologie from '../assets/staticTemp/inventronica/tehnologie1.jpg'

export default function Inventronica() {
    const sRef = useRef(null);

    return (
        <DefaultPage>
            <Helmet>
                    <title> INVENTRONICA </title>
                    <meta property="og:url"                content={`https://academiadeinfo.ro/article/inventronica`} />
                    <meta property="og:type"               content="article" />
                    <meta property="og:title"              content={"INVENTRONICA - Electronică, Programare și Invoație"} />
                    <meta property="og:description"        content={`Descoperă lumea extraordinară a electronicii aplicate, la diferite nivele: de la inițiere în electronică la automatizări cu microcontrolere, de la realizarea fizică de montaje electronice și tehnologia lipirii până la dezvoltarea unor proiecte de inovație și participarea la competiții naționale și internaționale. Proiect al Academiei de Informatică, finanțat de Fondul Științescu gestionat de Fundația Comunitară Brașov.`} />
                    {/* <meta property="og:video"              content="https://youtu.be/M-s97WkepcM"/> */}
                    <meta property="og:image"              content={photo_inventronica} />
            </Helmet>
            
            <NavbarAI />

            <PageSegment top first style={{minHeight:'min-content', paddingBottom:'128px'}}>
                <div className={style.divided}>
                    <div className={style.left}>
                        <h1><strong>INVENTRONICA</strong> - Electronică, Programare și Invoație</h1>
                        
                        {/* <Like href={`https://academiadeinfo.ro/article/${art.id}`} colorScheme="dark" showFaces share /> */}
                    </div>
                    <div className={style.right}>
                        <img
                            src={photo_inventronica}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "40vh",
                                objectFit: "contain"
                            }}
                        />
                    </div>
                    <h3>Proiect implementat de Academia de Informatcă și finanțat de Fondul Științescu gestionat de Fundația Comunitară Brașov.</h3>
                    {/* <ShareButton href={`https://academiadeinfo.ro/article/${art.id}`}>Share</ShareButton>  */}
                </div>
            </PageSegment>
            
            <PageSegment mid>
                <div className={style.articleBody}>
                    <h3><strong>INVENTRONICA</strong>, (combinația sinergică între <strong>INVENtica</strong> și <strong>elecTRONICA</strong>) este un proiect ce isi propune sa aducă beneficiarilor revelația potentialităților oferite in prezent de domeniul electronicii, prin aplicații practice la diferite nivele de impact: de la inițiere in electronică la automatizari cu microcontrollere, de la realizarea fizică de montaje electronice simple până la implementarea unor proiecte de inovație și participarea la competițiile de profil. </h3>
                    <center><div className={style.myButton} onClick={()=>{sRef.current.scrollIntoView({behavior: 'smooth'})}}>Sari la Înscriere</div></center>
                    <h3>Urmărim o învățare practică, prin care participanții să descopere faptul că electronica reprezintă o lume fascinantă, plină de posibilități. Prin aceasta, își pot pune aplicare cunoștiințele, gândirea logică și creativitatea aducând la viață atât teoria învățată cât și porpriile idei prin crearea unor montaje, roboți și dispozitive reale, funcționale.</h3>
                    <h3>
                        <ul>
                            Implementarea proiectului reclamă accesarea cunostințelor din diverse domenii:
                            <li>Fizică: curent electric, rezistente, condensatoare, legea Ohm etc;</li>
                            <li>Tehnologie electronică (tehnologia lipirii, tehnologia realizării plăcilor PCB);</li>
                            <li>Programare (atât in limbaje vizuale precum Scratch, cât și in limbaje cod precum Python sau C++);</li>
                            <li>Automatizari cu microcontrollere (Teoria sistemelor) ;</li>
                            <li>Mecanică;</li>
                            <li>Elemente de biofizică (pentru clasa de CDI).</li>
                        </ul>
                    </h3>
                </div>
            </PageSegment>

            <PageSegment mid>
                <div className={`${style.articleBody}`}>
                <h2 style={{textAlign: "center"}}> <strong>Workshop: HOMETRONICA - Inițiere în electronică</strong></h2>
                <center>
                <iframe width="560" height="464" src="https://www.youtube.com/embed/ld6SaUpGuLY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </center>
                <h3></h3>
                <h3>Participanții vor învăța câteva din bazele electronicii într-un  mod cât se poate de practic:  
                    curentul electric, surse de alimentare, surse de semnal, componente de circuit (rezistoare, condensatoare, diode, întrerupătoare , motoare, fotorezistențe etc), 
                    legarea serie si paralel, legea lui Ohm etc.
Mai intâi se prezinta câteva noțiuni teoretice, apoi se realizează practic diferite circuite cu ajutorul elementelor din kit, astfel beneficiarii putând observa funcționarea acestora. 
Partea practică va ocupa circa 70% din timpul lecției.</h3>  
                <h3>Activitatea presupune 4 ore, împărțite în 2 lecții. Workshop este recomandat pentru elevi din clasele 5-8.</h3>
                </div>
            </PageSegment>

            <PageSegment mid>
                <div className={`${style.articleBody}`}>
                <h2 style={{textAlign: "center"}}> <strong>Workshop Automatica Micro:bit</strong></h2>
                <center>
                 <img maxWidth="600px" src = {photo_microbit}></img>
                </center>
                <h3></h3>
                <h3>Ai învățat deja Scratch/MakeCode și robotica cu WeDo? Treci la pasul următor în acest workhop gratuit din cadrul proiectului Inventronica, unde vei învăța să construiești roboți și automatizări mai avansate, pe care să le programezi apoi cu blocuri!</h3> 

                <h3>Workshpul constă în 2 lecții a câte 2 ore, unde 8 participanți vor realizarea montaje practice de automatica și robotica cu ajutorul unor kituri dedicate, și le vor programa cu ajutorul unui limbaj de programare vizuală (Scratch sau MakeCode). </h3>
                <h3>Printre aplicațile vizate se numără exemple precum masinute, semafor inteligent, lampa cu senzor, catapulta automată sau ventilator cu senzor.</h3>
                </div>
            </PageSegment>
            
            <PageSegment mid>
                <div className={`${style.articleBody}`}>
                <h2 style={{textAlign: "center"}}> <strong>Atelier CDI: Cercetare-Dezvoltare-Inovare</strong></h2>
                <h3 style={{textAlign: "center"}}>Imaginează-ți, construiește și programează pentru o lume mai bună!</h3>
                <center>
                 <img maxWidth="600px" src = {photo_banner}></img>
                </center>
                <h3>Atelierul constă în 32 de sesiuni cu durata de cel puțin 2 ore fiecare. În prima etapă se vor cerceta probleme actuale și idei inovative de proiecte pentru rezolvarea acestora, vor fi selectate cele mai fezabile, se vor achiziționa materialele necesare și se vor realiza practic. Proiectele vor fi individuale sau pe echipă, la alegerea participanților.</h3>
                <h3>După dezvoltarea proiectelor, acestea vor fi înscrise în competiții de inovație pentru elevi naționale și / sau internaționale precum Competiția DaVinci, ROSEF, Olimpiada de Inovație și creație digitală etc.</h3>
                </div>
            </PageSegment>
            
            <PageSegment mid>
                <div className={`${style.articleBody}`}>
                <h2 style={{textAlign: "center"}}> Workshop Tehnologie Electronică </h2>
                <center>
                 <img maxWidth="600px" src = {photo_tehnologie}></img>
                </center>
                <h3>Participanții vor învăța cum se realizează practic o placă de circuit PCB (Printed Circuit Board), vor învăța tehnologia lipirii cu letconul a componentelor electronice pe o placa de circuit și vor crea montaje electronice.</h3>
                </div>
            </PageSegment>

            <PageSegment  mid>
                <h2 ref={sRef} style={{textAlign: "center"}}> Formular înscriere </h2>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd7CpgsqPu1AZiiTbGKsu5n_oEZXZu5vNWfQqZOi6wW67lfIg/viewform?embedded=true" width="100%" height="2346" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </PageSegment>

            <PageFooter />
        </DefaultPage>
    )
}
