import React, {useRef, useCallback, useEffect} from 'react'
import Slider from "react-slick";
import {Link} from 'react-router-dom'

import pageSegmentStyle from '../styles/pageSegment.module.scss'
import style from '../styles/landing_intro.module.scss'

import f1 from '../assets/photos/robo1_square.jpg'
import f2 from '../assets/photos/prog2_square.jpg'
import f3 from '../assets/photos/robojr1_square.jpg'
import f4 from '../assets/photos/prog1_square.jpg'

let sliderSettings = {
    key:'s1_inro',
    dots:false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2800,
    lazyLoad: true,
    nextArrow: null,
    prevArroe: null
}

export default function Landing_intro(props) {
    const containerRef = useRef(null);
    const inView = useCallback(
        () => {
            containerRef.current.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest"});
        }
    )
    props.intoViewHandler && props.intoViewHandler(inView);
    useEffect(() => {
        props.onLoad && props.onLoad();
    }, [])

    return (
        <div ref={containerRef} className = {pageSegmentStyle.pageTop}>
            <div className = {style.container}>
                <Slider {...sliderSettings} className={style.left}>
                    <img src={f1}></img>
                    <img src={f2}></img>
                    <img src={f3}></img>
                    <img src={f4}></img>
                </Slider>
                <div className={style.right}>
                    <h1>
                        Cursuri de 
                        <span className={style.color1}> programare</span> și 
                        <span className={style.color2}> robotică </span>
                        <span className={style.color3}> pentru elevi </span>
                    </h1>
                    <p>Începe chiar azi, indiferent de vârstă, <br></br>cariera ta în informatică!</p>
                    <ul>
                        <li>Vrei să înveți să gândești sistematic?</li>
                        <li>Vrei să înveți practic si distractiv?</li>
                        <li>Vrei să construiești și aduci la viață propriul robot?</li>
                        <li>Vrei să proiectezi propriul site web?</li>
                        <li>Vrei să creezi propriul joc pe calculator sau telefon?</li>
                        <li>Vrei să înțelegi mai bine materiile exacte?</li>
                    </ul>
                    <Link to='/courses' className="myButton">Începe aici!</Link>
                </div>
            </div>
        </div>
    )
}
