import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Route, Link, Switch, Redirect} from 'react-router-dom';

import Landing from './pages/Landing'
import Signup from './pages/Signup'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./styles/slick_extra.scss";
import 'react-placeholder/lib/reactPlaceholder.css';
import 'react-circular-progressbar/dist/styles.css';
import 'semantic-ui-css/semantic.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import './styles/mainStyle.scss'
import Contact from './pages/Contact';
import Courses from './pages/Courses';
import Article from './pages/Article';
import Course from './pages/Course';
import Achivs from './pages/Achievements';
import Sponsor from './pages/Sponsor';
import Achievement from './pages/Achievement';
import { FacebookProvider } from 'react-facebook';
import Partenair from './custom_articles/partenair';
import custom_articles from './custom_articles/customArticlesIndex'
import { Helmet } from 'react-helmet';



function App() {
  return (
    <div className="App">
      <FacebookProvider appId = "1649178495126165">
          <Route exact path='/home/:segment' component={Landing} />
          <ScrollToTop>
        <Switch>
            <Route exact path='/'>
              <Landing />
            </Route>
            <Route exact path={['/signup', '/join']}>
              <Signup />
            </Route>
            <Route exact path='/contact'>
              <Contact/>
            </Route>
            <Route exact path='/courses'>
              <Courses/>
            </Route>
            <Route exact path='/Sponsor'>
              <Sponsor />
            </Route>
            <Route exact path='/achievements'>
              <Achivs/>
            </Route>
            <Route exact path={['/partenair']}>
              <Partenair/>
            </Route>
            {
              custom_articles.map((a)=>(
                <Route exact path= {`/article/${a.id}`} component = {a.page}/>
              ))
            }
            <Redirect from='/orar' to = '/article/70'/> 
            <Route path='/article/:id' component={Article}/>
            <Route path='/achievement/:id' component={Achievement}/>
            <Route path='/course/:id' component={Course}/>
        </Switch>
          </ScrollToTop>
      </FacebookProvider>
    </div>
  );
}
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default App;
