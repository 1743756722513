import React from 'react'
import DefaultPage, { PageSegment } from '../components/DefaultPage'
import NavbarAI from '../components/NavbarAI'
import PageFooter from '../components/PageFooter'
import {Helmet} from 'react-helmet'

export default function Contact() {
    return (
        <DefaultPage>
            <Helmet>
                <title>A[i] - Contact</title>
            </Helmet>
            <NavbarAI/>
            <div style={{marginTop: 100}}></div>
            <PageFooter/>
            <PageSegment bot theme="orange" >
                <iframe 
                    src="https://docs.google.com/forms/d/e/1FAIpQLSdoWCHWJ5OVzQNZEJq9GlGspU1g5L_R-ALoLt4zt8CvMw5m6w/viewform?embedded=true" 
                    frameborder="0" 
                    marginheight="0" 
                    marginwidth="0"
                    style={{
                        width:"100%",
                        height: "900px"
                    }}
                >
                    Loading…
                </iframe>
            </PageSegment>
        </DefaultPage>
    )
}
