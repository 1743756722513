import React, { useState, useRef, useEffect} from 'react'
import ElectricGridBg from '../components/ElectricGridBg'
import style from '../styles/pageSegment.module.scss'
import { Helmet } from 'react-helmet'


export default function DefaultPage(props) {
    return (
        <div className="page" style={props.style}>
            <Helmet>
                <meta property="fb:app_id"             content="1649178495126165"/>
            </Helmet>
            <ElectricGridBg
                style = {{
                    width: "100wh",
                    height: "100vh",
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: -5
                }}
                />
            <div className={style.wrapper}>
                {props.children}
            </div>
        </div>
    )
}

export function PageSegment(props) {
    const segRef = useRef(null);
    const [handler, setHandler] = useState({});
    if(props.handler)
        props.handler(handler);
    useEffect(() => {
        handler.intoView = ()=>{
            segRef.current.scrollIntoView({behavior: 'smooth'});
        }
    });

    let variant = props.top?'pageTop':props.bot?'pageBot':props.mid?'pageMid':'pageDefault';
    variant = style[variant];
    if(props.ClassName)
        variant +=" "+props.ClassName;
    if(props.theme)
        variant +=" "+style[props.theme];
    if(props.first)
        variant +=" "+style.first;
    return (
        <div ref={segRef} style={props.style} className={variant}>
            {props.title?
                <div className={style.segmentTitle}>
                    {props.title}
                </div>
            :null}
            <div style = {props.innerStyle} className={style.container  + " " + props.innerClassName}>
                {props.children}
            </div>
        </div>
    )
}
