import React from 'react'

import pageSegmentStyle from '../styles/pageSegment.module.scss'
import style from '../styles/footer.module.scss'
import sigla from '../assets/sigla1024.png'
import { Image, Icon, Label, List } from 'semantic-ui-react'

export default function PageFooter() {
    return (
        <div className = {pageSegmentStyle.pageBot+" "+style.container}>
            <div className={style.left}>
                <Image src={sigla}/>
            </div>
            <div className={style.right}>
                <h1>ACADEMIA DE <br></br>INFORMATICĂ</h1>
                <List size="big">
                    <List.Item size="big" fluid>
                        <List.Icon verticalAlign='middle' size="big" name='phone'></List.Icon>
                        <List.Content verticalAlign='middle'>
                            <List.Header as="a" href="tel:+40771795125">(+40) 771 795 125</List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item size="big" fluid>
                        <List.Icon verticalAlign='middle' size="big" name='mail'></List.Icon>
                        <List.Content verticalAlign='middle'>
                            <List.Header as='a' href = "mailto: contact@academiadeinfo.ro">contact@academiadeinfo.ro</List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item size="big" fluid>
                        <List.Icon verticalAlign='middle' size="big" name='facebook official'></List.Icon>
                        <List.Content verticalAlign='middle'>
                            <List.Header as='a' href='https://fb.com/academiadeinformatica' target="blank">fb.com/academiadeinformatica </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item size="big" fluid>
                        <List.Icon verticalAlign='middle' size="big" name='map'></List.Icon>
                        <List.Content verticalAlign='middle'>
                            <List.Header as='a' href='https://g.page/academiadeinfo?share'>Str. Rozelor nr. 48, <br></br>500381, Brașov</List.Header>
                        </List.Content>
                    </List.Item>
                </List>
            </div>
        </div>
    )
}
