import React from 'react'
import DefaultPage, { PageSegment } from '../components/DefaultPage'
import NavbarAI from '../components/NavbarAI'
import PageFooter from '../components/PageFooter'
import axios from 'axios'
import { useState, useEffect, useRef, useCallback} from 'react'
import { Placeholder, Loader, Dimmer, Segment, Image, Rail } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import htmlParse from 'html-react-parser';
import Slider from 'react-slick'
import style from '../styles/article.module.scss';
import ImgsViewer from 'react-images-viewer';
import { Helmet } from 'react-helmet'
import {ShareButton, Like} from 'react-facebook/dist/ShareButton'

export default function Article() {
    const [art, setArt] = useState(null);
    const [artContent, setArtContent] = useState(null);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [currentImg, setCurrentImg] = useState(0);
    const { id } = useParams();

    let aux = Math.floor(window.innerWidth / 400);
    const mediaSlides = Math.max(1, aux - 1 + aux % 2);

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        axios
            .get('https://academiadeinfo.ro/api/get/article/' + id)
            .then(({ data }) => {
                setArt(data);
            })
            .catch(console.log)
    }, [])

    useEffect(() => {
        if (art)
            if(art.articleBody)
                setArtContent(htmlParse(art.articleBody))
            else
                setArtContent(
                    <h3>{art.shortDescription}</h3>
                )
    }, [art])

    const nextImg = useCallback(
        () => {
            console.log('next img pls');
            setCurrentImg(currentImg+1)
        },
        [currentImg],
    )
    const prevImg = useCallback(
        () => {
            setCurrentImg(currentImg-1)
        },
        [currentImg],
    )
    const openGallery = useCallback(
        (ind) => {
            setCurrentImg(ind?ind:0);
            setGalleryOpen(true);
        },
        [],
    )
    const closeGallery = useCallback(
        () => {
            setGalleryOpen(false)
        },
        [],
    )

    return (
        <DefaultPage>
            
                {art?
            <Helmet>
                    <title>A[i] - {art.title} </title>
                    <meta property="og:url"                content={`https://academiadeinfo.ro/article/${art.id}`} />
                    <meta property="og:type"               content="article" />
                    <meta property="og:title"              content={art.title} />
                    <meta property="og:description"        content={art.shortDescription} />
                    <meta property="og:image"              content={art.imageLandscape} />
            </Helmet>
                :
                    <title> Loading Article </title>
                }
            
            <NavbarAI />

            <PageSegment mid first title={art ? null : 'Loading article...'} style={{minHeight:'min-content', paddingBottom:'128px'}}>
                {art ?
                    <div className={style.divided}>
                        <div className={style.left}>
                            <h1>{art.title}</h1>
                            {/* <Like href={`https://academiadeinfo.ro/article/${art.id}`} colorScheme="dark" showFaces share /> */}
                        </div>
                        <div className={style.right}>
                            <img
                                src={art.imageLandscape}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "40vh",
                                    objectFit: "contain"
                                }}
                            />
                        </div>
                        {/* <ShareButton href={`https://academiadeinfo.ro/article/${art.id}`}>Share</ShareButton> */}
                    </div>

                    :
                    <Segment>
                        <Dimmer active>
                            <Loader active size='large' />
                        </Dimmer>
                        <p style={{ height: '50vh' }} />
                    </Segment>
                }
            </PageSegment>
            {art?
                <PageSegment mid>
                    <div className={style.articleBody}>
                        {artContent}
                    </div>
                </PageSegment>
            :null} 

            {art && art.gallery && (art.gallery.images[0] || art.gallery.videos[0]) ?
                <PageSegment mid>
                    <ImgsViewer
                        imgs={art.gallery.images}
                        isOpen={galleryOpen}
                        onClickPrev={prevImg}
                        onClickNext={nextImg}
                        onClose={closeGallery}
                        currImg={currentImg}
                    />
                    
                    <Slider
                        className={"center " + style.slider}
                        centerMode
                        pauseOnHover
                        pauseOnFocus
                        arrows={false}
                        infinite
                        centerPadding="100px"
                        slidesToShow={Math.max(1, Math.min(mediaSlides, art.gallery.images.length-1))}
                        speed={500}
                        autoplaySpeed={2000}
                        autoplay
                    >

                        {art.gallery.images.map((im, i) =>
                            <div key={im.id} className={style.slide} onDoubleClick={()=>openGallery(i)}>
                                <img src={im.src} />
                            </div>
                        )}
                        {art.gallery.videos.map(vid =>
                            <div key={vid.id} className={style.slide}>
                                <video controls>
                                    <source src={vid.src} />
                                </video>
                            </div>
                        )}
                    </Slider>
                    (Double Click pe Imagine pentru a mări)
                </PageSegment>
                : null
            }

            <PageFooter />
        </DefaultPage>
    )
}
