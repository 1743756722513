import React from 'react'
import DefaultPage, { PageSegment } from '../components/DefaultPage'
import NavbarAI from '../components/NavbarAI'
import PageFooter from '../components/PageFooter'
import axios from 'axios'
import { useState, useEffect, useRef, useCallback } from 'react'
import { Placeholder, Loader, Dimmer, Segment, Image, Rail, Item } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import htmlParse from 'html-react-parser';
import Slider from 'react-slick'
import style from '../styles/article.module.scss';
import ImgsViewer from 'react-images-viewer';
import { Helmet } from 'react-helmet'
import { ShareButton, Like } from 'react-facebook/dist/ShareButton'
import HelpIcon from '../assets/Icons/help_color.svg'
import { Link } from 'react-router-dom'

// --- FILES ---
import rodroids from '../assets/staticTemp/droidsChampions2021.jpg'
//downloads
import procedura from '../assets/docs/proceduraSponsorizare.pdf';
import contract from '../assets/docs/contractSponsorizare2021.docx';
// import cerere from '../assets/docs/cerereSponsorizare2021.pdf';
import cerere2suta from '../assets/docs/cerere2suta.pdf';
import atestatANAF from '../assets/docs/atestatANAF.pdf';
import raportActivitate from '../assets/docs/Raport2021.pdf';

export default function Sponsor() {
    return (
        <DefaultPage>
            <Helmet>
                <title>A[i] - {"Sponsor"} </title>
                <meta property="og:url" content={`https://academiadeinfo.ro/Sponsor`} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={"Susțineți educația și excelența în IT!"} />
                <meta property="og:description" content={"Susțineți studenții dar și lectorii noștri în activitatea de cercetare-dezvolare de proiecte inovative, și oferiți tinerilor oportunitatea de a reprezenta Brașovul și România la concursuri naționale și internaționale."} />
                {/* <meta property="og:image"              content={TODO} /> */}
            </Helmet>

            <NavbarAI />

            <PageSegment mid first style={{ minHeight: 'min-content', paddingBottom: '128px' }}>
                <div className={style.divided}>
                    <div className={style.left}>
                        <h1>{"Susțineți educația și excelența în IT"}</h1>
                        {/* <Like href={`https://academiadeinfo.ro/article/${art.id}`} colorScheme="dark" showFaces share /> */}
                    </div>
                    <div className={style.right}>
                        <img
                            src={HelpIcon}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "40vh",
                                objectFit: "contain"
                            }}
                        />
                    </div>
                    {/* <ShareButton href={`https://academiadeinfo.ro/article/${art.id}`}>Share</ShareButton> */}
                </div>
            </PageSegment>

            <PageSegment mid>
                <div className={style.articleBody}>
                    <h3> <strong>Susțineți educația și excelența în IT:</strong></h3>
                    <h3>
                        <ul>
                            <li>Ajutați studenții dar și lectorii noștri în activitatea de cercetare-dezvolare de <strong> proiecte inovative </strong>;</li>
                            <li>Oferiți tinerilor oportunitatea de a reprezenta Brașovul și România la <strong>concursuri naționale și internaționale</strong>, merită!</li>
                        </ul>
                    </h3>
                    <h3> <a href={raportActivitate} target="blank"> Puteți vedea aici câteva din rezultatele pe care le-au avut aceștia în trecut.</a></h3>
                    {/* <h2 style={{textAlign: "center"}}>Nu vă costă nimic!</h2> */}
                    <h3> Precum fiecare persoană fizică poate redirecționa 2% sau 3.5% din impozitul pe venit spre un ONG așa și firmele pot redirecționa până la <b>20% din impozitul pe profit</b></h3>

                    <h2 style={{ textAlign: "center" }}>Nevoile noastre Acum</h2>
                    <Item.Header>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/JMmnIAIPi9M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Item.Header>
                    <Item.Group style={{ maxWidth: 700 }}>
                        <Item>

                            <Item.Content>
                                <Item.Header> Echipa RoDroids s-a calificat pentru Dubai</Item.Header>
                                <Item.Description>
                                    În luna noiembrie, echipele Academiei de Informatică din cadrul Fondului Științescu Brașov: RoDroids – nivel liceu și RoboGeții – nivel gimnaziu au ocupat, locul 2 , fiecare la categoria lor de vârstă, Concursul Național de Știință, Tehnologie și Inginerie pentru elevi ROSEF, calificându-se astfel, fiecare, pentru unul dintre Saloanele Internaționale de Inovație pentru elevi.
                                    Echipa RoDroids a primit invitație de participare la MILSET, Salonul Internațional de Știință și Tehnologie pentru elevi din Dubai, care va avea loc in luna februarie 2022.
                                    În cadrul MILSET Expo-Sciences Asia 2022 vor fi prezentate proiecte pregătite de către 800 participanți din 30 de țări. Vârsta participanților este cuprinsă între 9 și 25 ani.
                                    Proiectul nostru, BattAiry, oferă o metodă de a stoca sub formă de aer comprimat energia în exces generată de sursele regenerabile, adaptând moderna tehnologie CAES pentru gospodării individuale.
                                </Item.Description>
                                <Item.Extra><Link to="/partenair">Află mai multe</Link></Item.Extra>
                            </Item.Content>
                        </Item>
                        <Item>
                            <Item.Content>
                                <Item.Description>
                                    În momentul de față cei 3 tineri care formează echipa RoDroids, David Ignat, Dora Glavan și Paul Vartolomei, care au ocupat <b> LOCUL 1 la World Robotics Olympiad WRO 2021 </b>, își pregătesc pașapoartele și lucrează la îmbunătățirea proiectului, așa cum au recomandat jurații concursului ROSEF. Împreună cu mentorul, au stabilit calendarul pentru următoarele 10-12 întâlniri de lucru pentru ca prototipul prezentat în Dubai să aibă șanse maxime în cadrul jurizării.
                                    Costurile estimate pentru participarea în Dubai sunt de 5.000 Euro pentru cei trei elevi și pentru mentorul echipei care îi va însoți, astfel: transport 1.400 Euro, cazare, masă și taxa de participare 2.600 Euro, materialele pentru upgradarea proiectului actual 1000 Euro.
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </div>
            </PageSegment>

            <PageSegment mid>
                <div className={`${style.articleBody}`}>
                    <h2><center>Cum ne puteți ajuta?</center></h2>
                    <center><h2> <strong>Orice donație este binevenită!</strong> </h2></center>
                    <p> În continuare puteți descărca detalii despre procedura de sponsorizare și documentele necesare atât pentru persoane fizice cât și pentru persoane juridice.</p>
                    <h2 style={{ textAlign: "center" }}>Downloads</h2>
                    <div className={`${style.myButtonGroup} vertical`}>
                        {/* <a className={style.myButton} href={cerere} target="#" white-space="normal">Cerere Sponsorizare</a> */}
                        <a className={style.myButton} href={procedura} target="#" white-space="normal">Procedura Sponsorizare</a>
                        <a className={style.myButton} href={contract} target="#" white-space="normal">Contract Sponsorizare</a>
                        <a className={style.myButton} href={atestatANAF} target="#" white-space="normal">Atestat ANAF Sponsorizări</a>
                        <a className={style.myButton} href={cerere2suta} target="#" white-space="normal">Cerere 2% / 3.5%</a>
                    </div>


                </div>
            </PageSegment>

            <PageFooter />
        </DefaultPage>
    )
}
