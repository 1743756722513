import React from 'react'
import DefaultPage, { PageSegment } from '../components/DefaultPage'
import NavbarAI from '../components/NavbarAI'
import PageFooter from '../components/PageFooter'
import { Card, Icon, Image, Button, Step } from 'semantic-ui-react'
import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import style from '../styles/courses.module.scss'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function Courses() {
    const [courses, setCourses] = useState(null)
    const [coursesSegment, setCoursesSegment] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        axios
            .get('https://academiadeinfo.ro/api/get/courses')
            .then((r)=> {
                setCourses(r.data);
            })
            .catch((err)=> alert("unable to connect to server"));
        return () => {
        }
    }, [])
    
    return (
        <DefaultPage>
            <Helmet>
                <title>A[i] - Cursuri</title>
            </Helmet>
            <NavbarAI/>
            <PageSegment mid first title='Cursul perfect Pentru TINE' style={{minHeight:'min-content', paddingBottom:'128px'}}>
                <Step.Group align='left' size='huge' className={style.stepBG}>
                    <Step as={Link} onClick={()=>coursesSegment.intoView()} >
                        <Icon name='search'/>
                        <Step.Content>
                            <Step.Title>
                                Alege cursul
                            </Step.Title>
                            <Step.Description>
                                Vezi toate cursurile
                            </Step.Description>
                        </Step.Content>
                    </Step>
                    <Step as={Link} to='/contact'>
                        <Icon name='info'/>
                        <Step.Content>
                            <Step.Title>
                                Nu știi ce să alegi?
                            </Step.Title>
                            <Step.Description>
                                Contactează-ne!
                            </Step.Description>
                        </Step.Content>
                    </Step>
                    <Step as={Link} to='/signup'>
                        <Icon name='signup'/>
                        <Step.Content>
                            <Step.Title>
                                Ai decis?
                            </Step.Title>
                            <Step.Description>
                                Înscrie-te!
                            </Step.Description>
                        </Step.Content>
                    </Step>
                </Step.Group>
            </PageSegment>
            <PageSegment mid title='CURSURI' handler={(handler)=>setCoursesSegment(handler)}>
                {courses?
                    <Card.Group centered>
                    {courses.map(c=>
                        <Card key={c.id}>
                            <Image src={c.imageLandscape} className={style.cardImage} />
                            <Card.Content >
                                <Card.Header>{c.name}</Card.Header>
                                <Card.Description >
                                    {c.shortDescription}
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra align='center'>
                                <Link to={`/course/${c.id}`} className={style.myButton}>Află mai multe</Link>
                            </Card.Content>
                        </Card>
                    )}
                    </Card.Group>
                :
                    <>
                        <Icon loading name='spinner' />
                        <h1> Loading ...</h1>
                    </>
                }
            </PageSegment>
            <PageFooter />
        </DefaultPage>
    )
}
