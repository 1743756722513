import React from 'react'
import DefaultPage, { PageSegment } from '../components/DefaultPage'
import NavbarAI from '../components/NavbarAI'
import PageFooter from '../components/PageFooter'
import axios from 'axios'
import { useState, useEffect, useRef, useCallback } from 'react'
import { Placeholder, Loader, Dimmer, Segment, Image, Rail } from 'semantic-ui-react'
import { useParams, Link } from 'react-router-dom'
import htmlParse from 'html-react-parser';
import Slider from 'react-slick'
import style from '../styles/article.module.scss';
import ImgsViewer from 'react-images-viewer';
import splashImg from '../assets/splashpc.jpg';
import {Helmet} from 'react-helmet';

export default function Course() {
  const [art, setArt] = useState(null);
  const [artContent, setArtContent] = useState(null);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState(0);
  const { id } = useParams();

  let aux = Math.floor(window.innerWidth / 400);
  const mediaSlides = Math.max(1, aux - 1 + aux % 2);

  useEffect(()=>{
      window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get('https://academiadeinfo.ro/api/get/achievement/' + id)
      .then(({ data }) => {
        console.log("data: ", data);
        setArt(data);
      }).catch(console.log)
  }, [])

  useEffect(() => {
    if (art)
      if (art.articleBody)
        setArtContent(htmlParse(art.articleBody))
      else
        setArtContent(
          <h3>{art.shortDescription}</h3>
        )
  }, [art])

  const nextImg = useCallback(
    () => {
      setCurrentImg(currentImg + 1)
    },
    [currentImg],
  )
  const prevImg = useCallback(
    () => {
      setCurrentImg(currentImg - 1)
    },
    [currentImg],
  )
  const openGallery = useCallback(
    (ind) => {
      setCurrentImg(ind ? ind : 0);
      setGalleryOpen(true);
    },
    [],
  )
  const closeGallery = useCallback(
    () => {
      setGalleryOpen(false)
    },
    [],
  )

  return (
    <DefaultPage>
      {art ?
        <Helmet>
          <title>A[i] - {art.title} </title>
          <meta property="og:url" content={`https://academiadeinfo.ro/course/${art.id}`} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={"Curs A[i] - " + art.title} />
          <meta property="og:description" content={art.shortDescription} />
          <meta property="og:image" content={art.imageLandscape} />
        </Helmet>
        :
        <title> Loading Article </title>
      }
      <NavbarAI />

      <PageSegment bot first title={art ? null : 'Loading course info...'}
        style={{ 
          minHeight: 'min-content', 
          position: 'relative', 
          paddingBottom: '128px',
          padding: '0px'
        }}  
        >
        {art ?
          <div className={style.banner}>
            <img className={style.background} src={splashImg} />
            <div className={style.foreground}>
              <div className={style.overlay} />
              <h1>{art.title}</h1>
              <h2>{art.motto}</h2>
            </div>
          </div>

          :
          <Segment>
            <Dimmer active>
              <Loader active size='large' />
            </Dimmer>
            <p style={{ height: '50vh' }} />
          </Segment>
        }
      </PageSegment>
      {art ?
        <PageSegment top>
          <div className={style.articleBody}>
            {artContent}
          </div>
        </PageSegment>
        : null}

      {art && art.gallery && (art.gallery.images[0] || art.gallery.videos[0]) ?
        <PageSegment mid>
          <ImgsViewer
            imgs={art.gallery.images}
            isOpen={galleryOpen}
            onClickPrev={prevImg}
            onClickNext={nextImg}
            onClose={closeGallery}
            currImg={currentImg}
          />

          <Slider
            key={art.id}
            className={"center " + style.slider}
            pauseOnHover
            pauseOnFocus
            centerMode
            arrows={false}
            infinite
            centerPadding="100px"
            slidesToShow={Math.max(1, Math.min(mediaSlides, art.gallery.images.length-1))}
            speed={500}
            autoplaySpeed={2000}
            autoplay
          >

            {art.gallery.images.map((im, i) =>
              <div key={im.id} className={style.slide} onDoubleClick={() => openGallery(i)}>
                <img src={im.src} />
              </div>
            )}
            {art.gallery.videos.map(vid =>
              <div key={vid.id} className={style.slide}>
                <video controls>
                  <source src={vid.src} />
                </video>
              </div>
            )}
          </Slider>
                    (Double Click pe Imagine pentru a mări)
                </PageSegment>
        : null
      }

      <PageFooter />
    </DefaultPage>
  )
}