import React, {useEffect, useState, useRef, useCallback} from 'react'
import pageSegmentStyle from '../styles/pageSegment.module.scss'
import style from '../styles/landing_courses.module.scss'
import axios from 'axios'
import { Card } from 'semantic-ui-react';
import Slider from 'react-slick';
import {useWindowSize} from '../hooks/myHooks';
import {Image, Item} from 'semantic-ui-react'

import {Link} from 'react-router-dom';


export default function Landing_courses(props) {
    const [courses, setCourses] = useState([]);
    const [width, height] = useWindowSize();
    let nrPerR = Math.max(1, Math.floor((width-50) / 380));
    const settings = {
        key:'ss3_courses',
        dots: true,
        infinite: false,
        speed: 500,
        slidesToScroll: Math.max(1, nrPerR-1),
        slidesToShow: nrPerR,
    }
    const containerRef = useRef(null);
    const inView = useCallback(
        () => {
            containerRef.current.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest"});
        }
    )
    props.intoViewHandler && props.intoViewHandler(inView);
    useEffect(() => {
        props.onLoad && props.onLoad();
    }, [])

    useEffect(() => {
        axios
            .get('https://academiadeinfo.ro/api/get/courses')
            .then((r)=> {
                setCourses(r.data);
            })
            .catch((err)=> alert("unable to connect to server"));
        return () => {
        }
    }, [])

    return (
        <div ref={containerRef} className={`${pageSegmentStyle.pageMid} ${style.container}`}>
            <div className={style.pageTitle}>
                Cursuri
            </div>
            <div className={style.coursesWrapper}>
                <Slider {...settings} >
                    {
                        courses.map(c=>
                            <Card key={c.id}>
                                <Image align='center' src={c.imageLandscape} className={style.cardImage}></Image>
                                <Card.Content className={style.cardContent}>
                                    <Card.Header>{c.name}</Card.Header>
                                    <Card.Description className={style.cardDescription}>
                                        {c.shortDescription}
                                        <div className={style.fadeOverlay}></div>
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra align='center'>
                                    <Link to={`/course/${c.id}`} className={style.myButton}>Află mai multe</Link>
                                </Card.Content>
                            </Card>
                        )
                    }
                </Slider>
                <div className={style.myButtonGroup}>
                    <Link to='/courses' className={style.myButton}>
                        De unde să încep?
                    </Link>
                    <Link to='/courses' className={style.myButton}>
                        Toate cursurile
                    </Link>
                    <Link to='/signup' className={style.myButton}>
                        Vreau să mă înscriu
                    </Link>
                </div>
            </div>
        </div>
    )
}
