import React from 'react'
import DefaultPage, { PageSegment } from '../components/DefaultPage'
import NavbarAI from '../components/NavbarAI'
import PageFooter from '../components/PageFooter'
import axios from 'axios'
import { useState, useEffect, useRef, useCallback} from 'react'
import { Placeholder, Loader, Dimmer, Segment, Image, Rail, Item} from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import htmlParse from 'html-react-parser';
import Slider from 'react-slick'
import style from '../styles/article.module.scss';
import ImgsViewer from 'react-images-viewer';
import { Helmet } from 'react-helmet'
import {ShareButton, Like} from 'react-facebook/dist/ShareButton'
import HelpIcon from '../assets/Icons/help_color.svg'
import { Link } from 'react-router-dom'

// --- PHOTOES ---
import helmetPhoto from '../assets/staticTemp/helmet.png';
import helmet_left from '../assets/staticTemp/poza_left.jpg';
import helmet_open from '../assets/staticTemp/poza_deschisa_crop.jpg';
import helmet_right from '../assets/staticTemp/poza_right2.JPG';
import helmet_3in1 from '../assets/staticTemp/3in1.png';
import helmet_sport from '../assets/staticTemp/helmet_sport.png';
import helmet_supra from '../assets/staticTemp/helmet_supra.png';
import partenair_logo from '../assets/staticTemp/partenair_logo.png';
import helmet_racoritoare from '../assets/staticTemp/helmet_racoritoare2.jpg';


export default function Partenair() {
    return (
        <DefaultPage>
            <Helmet>
                    <title> PARTENAIR </title>
                    <meta property="og:url"                content={`https://youtu.be/M-s97WkepcM`} />
                    <meta property="og:type"               content="article" />
                    <meta property="og:title"              content={"PARTENAIR - Cască filtrantă anti-COVID"} />
                    <meta property="og:description"        content={`Academia de Informatică luptă împotriva pandemiei! 
Una din soluțiile în dezvoltare este „PARTENAIR” – Cască filtrantă anti-COVID
Inovativă, confortabilă, eficientă și ușor de folosit pentru a filtra microparticule, dar și alergeni, bacterii, microfloră, fungi, spori, virusuri (inclusiv SARS-CoV-2).
Dorim ca această cască să fie disponibilă tuturor. Ajutați-ne să facem următorul pas: multiplicarea prototipului și lansarea etapei de testare. 
Orice donație ne aduce mai aproape de atingerea acestui obiectiv.
Vă mulțumim!`} />
                    <meta property="og:video"              content="https://youtu.be/M-s97WkepcM"/>
                    <meta property="og:image"              content={helmetPhoto} />
            </Helmet>
            
            <NavbarAI />

            <PageSegment mid first style={{minHeight:'min-content', paddingBottom:'128px'}}>
                <div className={style.divided}>
                    <div className={style.left}>
                        <h1>{"PARTENAIR - Cască filtrantă anti-COVID"}</h1>
                        {/* <Like href={`https://academiadeinfo.ro/article/${art.id}`} colorScheme="dark" showFaces share /> */}
                    </div>
                    <div className={style.right}>
                        <img
                            src={helmet_right}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "40vh",
                                objectFit: "contain"
                            }}
                        />
                    </div>
                    {/* <ShareButton href={`https://academiadeinfo.ro/article/${art.id}`}>Share</ShareButton>  */}
                </div>
            </PageSegment>
            
            <PageSegment mid>
                <div className={style.articleBody}>
                    <h3> <center><strong>PARTENAIR - Cască filtrantă anti-COVID</strong></center></h3>
                    <h4>
                        “PARTENAIR” este o cască inovativă, confortabilă, eficientă și ușor de folosit pentru a filtra microparticule, dar și alergeni, bacterii, microfloră, fungi, spori, virusuri (inclusiv SARS-CoV-2). Virusurile sunt filtrate datorită faptului că particulele purtătoare sunt oprite.
                    </h4>
                    <h4><strong>Status: În dezvoltare</strong>. Am realizat primul exemplar complet funcțional.  Am înregistrat brevetul la OSIM: „Patent pending”.</h4>
                    <h3><center><strong>Dorim ca această cască să fie disponibilă tuturor. <br></br> Ajutați-ne să facem următorul pas!</strong><br></br><Link to="/sponsor"> Aflați aici cum</Link></center></h3>
                    
                    <center style={{width: "100%", maxWidth:700}}><iframe width="100%" scrolling="no" height="360px" src="https://www.youtube.com/embed/M-s97WkepcM" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" loop allowfullscreen></iframe> </center>                   
                    <img src = {helmet_3in1}></img>
                    <h3><center>PROTECȚIE <br></br> -mai eficientă de cel puțin 400 de ori decât măștile uzuale-</center></h3>
                    <h4>
                        Facând o comparație, putem nota că măștile dispozabile obișnuite protejează doar gura și nasul, de particule (inclusiv microbi) <strong>cu dimensiuni de până la 3 micrometri și cu o eficiență de doar 80%</strong>, in timp ce casca propusă asigură o protecție completă a capului (ochi, nas, urechi, gură) cu o filtrare a particulelor <strong> de până la 0.3 micrometri cu o eficiență de 99.95%.</strong>
                    </h4>
                    <h4>Ușoara suprapresiune împiedică pătrunderea aerului nefiltrat</h4>
                    <img src={helmet_supra}></img>
                    <h3><center>CONFORT </center>  </h3>
                    <h4>
                        <ul>
                            <li>Sistem reglabil și confortabil de fixare pe cap.</li>
                            <li><strong>Viziera rabatabilă</strong> permite utilizatorului, la ieșirea temporară din mediul contaminat, să mănânce, să bea, să-și șteargă fața/ nasul,  fără a fi nevoit să iși scoată casca în intregime.</li>
                            <li><strong>NO FOG: </strong>ventilarea continuă împiedică aburirea vizierei. </li>
                        </ul>
                    </h4>
                    <img src= {helmet_racoritoare}></img>
                    <h3><center>Comunicare clară și socializare în siguranță</center></h3>
                    <h4>Un sistem original de separatoare fonice <strong>protejează urechea utilizatorului</strong>, permițându-i să audă sunetele și vocile din mediul înconjurător, în același timp diminuând zgomotul intern al căștii  produs de propria voce și ventilatoare.</h4>
                    <h4>De asemenea, casca este dotată și cu un <strong>sistem de amplificare a vocii</strong>, pentru comunicare la distanță.</h4>
                    <h3><center>Oriunde, Oricând</center></h3>
                    <h4>Ventilatoare puternice dar silențioase. <strong>Volumul de aer poate fi ajustat</strong> în funcție de activitate, fie aceasta statică sau dinamică.</h4>
                    <h4>Dotată cu baterii Li-Ion 18650, “PARTENAIR” <strong>poate funcționa timp de aproximativ 8 ore </strong> cu o singură încărcare, efectuată printr-un port USB.</h4>
                    <img src = {helmet_sport}></img>
                </div>
            </PageSegment>

            <PageSegment mid>
                <div className={`${style.articleBody}`}>
                <h2 style={{textAlign: "center"}}>Nevoile noastre Acum</h2>
                    
                <h3>In momentul de față avem nevoie de resurse financiare pentru realizarea unui numar de 50 bucați de test, care sa fie distribuite unor persoane care isi doresc să le poarte pentru a ne da un feedback. Acest feedback ne va ajuta să facem o evaluare care să ne permită îmbunătățirea produsului.</h3>  
                <center>
                    <h2><Link to="/sponsor">Aflați cum ne puteți ajuta</Link></h2>
                    <h2>Mulțumim!</h2>
                    <img style={{maxWidth:300}} src={partenair_logo}></img>
                </center>
                </div>
            </PageSegment>

            <PageFooter />
        </DefaultPage>
    )
}
