import React, { useEffect, useState, useRef, useCallback } from 'react'
import classNames from 'classnames/bind'
import pageSegmentStyle from '../styles/pageSegment.module.scss'
import style from '../styles/landing_news.module.scss'
import axios from 'axios'
import ReactPlaceholder from 'react-placeholder';
import Slider from 'react-slick';
import { Link } from 'react-router-dom'
import { Like } from 'react-facebook'

import 'semantic-ui-css/semantic.min.css'

import cn from 'classnames';

import { Card, Item, Loader, Image, Dimmer } from 'semantic-ui-react'

import custom_articles from '../custom_articles/customArticlesIndex';




var cx = classNames.bind(pageSegmentStyle);

const pinnedId = null;


export function LandingNewsCard(props) {
    let { article, className } = props;
    console.log(article);
    return (
        <div className={cn(style.card, className)}>
            <div className={style.head}>
                <img src={article.imageLandscape} />
                <div className={style.column}>
                    <h2> {article.title}</h2>
                    <Link to={`/article/${article.id}`} className='myButton'>Află mai mult</Link>
                </div>
            </div>
            <p> {article.shortDescription} </p>
        </div>
    )
}

export default function Landing_news(props) {
    const [news, setNews] = useState([]);
    const [pinned, setPinned] = useState([]);
    const [allNews, setAllNews] = useState([]);

    const containerRef = useRef(null);
    const inView = useCallback(
        () => {
            containerRef.current.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
        }
    )
    props.intoViewHandler && props.intoViewHandler(inView);
    useEffect(() => {
        props.onLoad && props.onLoad();
    }, [])

    useEffect(() => {
        axios
            .get('https://academiadeinfo.ro/api/get/articles')
            .then(({ data }) => {
                let hot = data.filter(x => x.hot).sort((a, b) => b.id - a.id);
                setNews(hot);
            })
            .catch((err) => alert("unable to connect to server"));
        return () => {
        }
    }, [])

    useEffect(() =>
        setPinned(news && (news.find(x => x.id == pinnedId) || news[0]))
        , [news])


    return (

        <div ref={containerRef} className={`${pageSegmentStyle.pageMid} ${style.container}`}>
            <div className={style.pageTitle}>
                News
            </div>
            <div className={style.bigNews}>
                <Dimmer active={!news[0]}>
                    <Loader />
                </Dimmer>
                {pinned ?
                    <Card style={{ width: "90%", margin: "auto" }}>
                        <Image src={pinned.imageLandscape} />
                        <Card.Content>
                            <Card.Header><h1>{pinned.title}</h1></Card.Header>
                            <Card.Description >
                                <p>{pinned.shortDescription}</p>
                            </Card.Description>
                        </Card.Content>
                        {pinned.articleBody ?
                            <Card.Content extra>
                                <Link to={`/article/${pinned.id}`} className='myButton'>Află mai mult</Link>
                            </Card.Content>
                            : null
                        }
                        <Like href={`https://academiadeinfo.ro/article/${pinned.id}`} colorScheme="light" showFaces share />
                    </Card>
                    : null
                }
            </div>
            <div className={style.allNews}>
                <Item.Group>
                    {
                        news.map((x, i, arr) =>
                            (!pinned || x.id != pinned.id) && <LandingNewsCard article={x} />
                        )
                    }
                </Item.Group>
            </div>
        </div>
    )
}
