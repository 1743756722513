import React, {useRef, useCallback, useEffect} from 'react'
import Slider from "react-slick";

import pageSegmentStyle from '../styles/pageSegment.module.scss'
import style from '../styles/landing_about.module.scss'
import { CircularProgressbar,CircularProgressbarWithChildren } from 'react-circular-progressbar';

import f1 from '../assets/sigla1024.png'
import f2 from '../assets/photos/infolab2.jpg'
import f3 from '../assets/photos/prog3_square.jpg'
import f4 from '../assets/photos/mansarda2.jpg'
import f5 from '../assets/photos/robojr2_square.jpg'
import f6 from '../assets/photos/concurs3.jpg'

import { Statistic, Card } from 'semantic-ui-react';



export default function Landing_about(props) {
    const containerRef = useRef(null);
    const inView = useCallback(
        () => {
            containerRef.current.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest"});
        }
    )
    props.intoViewHandler && props.intoViewHandler(inView);
    useEffect(() => {
        props.onLoad && props.onLoad();
    }, [])
    
    const sliderSettings = {
        key: 's2_about',
        dots:false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2800,
        lazyLoad: true,
        nextArrow: null,
        prevArroe: null
    }
    return (
        <div ref={containerRef} className = {pageSegmentStyle.pageMid}>
            <div className = {style.container}>
                <div className={style.pageTitle}>
                    ACADEMIA <span className={style.small}> de </span>  INFORMATICĂ
                </div>
                <div className={`${style.circles} ${style.left}`}>
                    <div className={style.circle}>
                        <CircularProgressbarWithChildren value={66}>
                            <Statistic>
                                <Statistic.Value>21</Statistic.Value>
                                <Statistic.Label>Cursuri</Statistic.Label>
                            </Statistic>
                        </CircularProgressbarWithChildren>
                    </div>
                    <div className={style.circle}>
                        <CircularProgressbarWithChildren value={37}>
                            <Statistic>
                                <Statistic.Value>1674</Statistic.Value>
                                <Statistic.Label>Inițiați</Statistic.Label>
                            </Statistic>
                        </CircularProgressbarWithChildren>
                    </div>
                </div>
                <Slider {...sliderSettings} className={style.slider}>
                    <img src={f1}></img>
                    <img src={f2}></img>
                    <img src={f3}></img>
                    <img src={f1}></img>
                    <img src={f4}></img>
                    <img src={f5}></img>
                    <img src={f6}></img>
                </Slider>
                <div className={`${style.circles} ${style.left}`}>
                <div className={style.circle}>
                        <CircularProgressbarWithChildren value={47}>
                            <Statistic>
                                <Statistic.Value>5</Statistic.Value>
                                <Statistic.Label>Ani</Statistic.Label>
                            </Statistic>
                        </CircularProgressbarWithChildren>
                    </div>
                    <div className={style.circle}>
                        <CircularProgressbarWithChildren value={72}>
                            <Statistic>
                                <Statistic.Value>13</Statistic.Value>
                                <Statistic.Label>Profesori</Statistic.Label>
                            </Statistic>
                        </CircularProgressbarWithChildren>
                    </div>
                </div>
                <div className={style.cards}>
                    <Card.Group fluid centered>
                        <Card>
                            <Card.Content>
                                <Card.Header>Cine suntem?</Card.Header>
                                <Card.Description>Un centru de <span className={style.color1}> formare și excelență </span> în domeniul IT pentru tineri de la 8 la 88 de ani</Card.Description>
                            </Card.Content>
                        </Card>
                        <Card>
                            <Card.Content>
                                <Card.Header>Misiune</Card.Header>
                                <Card.Description>Misiunea noastră este aceea de a oferi o <span className={style.color1}> formare completă </span> în IT tinerilor pentru a le asigura o <span className={style.color1}>carieră de succes în România</span>, astfel încât să nu mai fie nevoiți să emigreze.</Card.Description>
                            </Card.Content>
                        </Card>
                        <Card>
                            <Card.Content>
                                <Card.Header>Ce facem</Card.Header>
                                <Card.Description>Oferim <span className={style.color1}>cursuri, workshop-uri, pregătire pentru concursuri</span> naționale si internaționale; activități educaționale distractive, training specializat și multe altele.</Card.Description>
                            </Card.Content>
                        </Card>
                        <Card>
                            <Card.Content>
                                <Card.Header>Cum facem</Card.Header>
                                <Card.Description>Toate activitățile noastre sunt <span className={style.color1}> practice </span>, căutând să descopere <span className={style.color1}> talentele </span> fiecărui student, să formeze un <span className={style.color1}> mod de gândire logic </span> și sistematic, să inspire <span className={style.color1}> pasiune.</span> </Card.Description>
                            </Card.Content>
                        </Card>
                        <Card>
                            <Card.Content>
                                <Card.Header>Viziune</Card.Header>
                                <Card.Description>Vrem să vedem <span className={style.color1}> o nouă generație mai tare decât noi </span>, tineri care învață cu plăcere, nu fug de provocări, fac lucrurile cu entuziasm și pasiune, crează un viitor mai bun.</Card.Description>
                            </Card.Content>
                        </Card>
                    </Card.Group>
                </div>
            </div>
        </div>
    )
}
