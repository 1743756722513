import React from 'react'
import DefaultPage, { PageSegment } from '../components/DefaultPage'
import NavbarAI from '../components/NavbarAI'
import PageFooter from '../components/PageFooter'
import { Card, Icon, Image, Button, Step } from 'semantic-ui-react'
import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import style from '../styles/courses.module.scss'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function Achievements() {
    const [achivs, setAchivs] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0);
        axios
            .get('https://academiadeinfo.ro/api/get/achievements')
            .then((r)=> {
                setAchivs(r.data);
            })
            .catch((err)=> alert("unable to connect to server"));
        return () => {
        }
    }, [])
    
    return (
        <DefaultPage>
            <Helmet>
                <title>A[i] - Cursuri</title>
            </Helmet>
            <NavbarAI/>
            <PageSegment mid first title='Tot ce facem, cu Excelență' style={{minHeight:'min-content', paddingBottom:'128px'}}>
                
            </PageSegment>
            <PageSegment mid>
                {achivs?
                    <Card.Group centered>
                    {achivs.sort((a, b)=>b.id-a.id).map(c=>
                        <Card key={c.id}>
                            <Image src={c.imageLandscape} className={style.cardImage} />
                            <Card.Content >
                                <Card.Header>{c.name}</Card.Header>
                                <Card.Description >
                                    {c.shortDescription}
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra align='center'>
                                <Link to={`/achievement/${c.id}`} className={style.myButton}>Află mai multe</Link>
                            </Card.Content>
                        </Card>
                    )}
                    </Card.Group>
                :
                    <>
                        <Icon loading name='spinner' />
                        <h1> Loading ...</h1>
                    </>
                }
            </PageSegment>
            <PageFooter />
        </DefaultPage>
    )
}
